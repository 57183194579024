import React from 'react'
import Layout from '../../components/Layout'
import { SEO } from '../../components/seo'
import ClientReviews from '../../components/ClientReviews'
import eCommerceDesignBanner from '../../images/e-commerce-design.webp'
import eCommerceWebDesign from '../../images/e-commerce-website-design.webp'
import eCommerceWebDesignFunction from '../../images/e-commerce-web-design-functions.webp'

const pStyles = {
  fontSize: "18px",
  lineHeight: "1.5",
  letterSpacing: ".5px"
}

const rowPadding = {
  padding: "150px 0"
}

export default function eCommerceDesign() {
  return (
    <Layout>
      <section id='web_design' className='container'>
        <div className='row gx-5 flex-row-reverse' style={rowPadding}>
          <div className='col-md-6'>
            <div className='ms-md-5 position-relative'>
                <img src={eCommerceDesignBanner} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h1 className='display-5 mb-4'>E-commerce Design</h1>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              E-Commerce market is growing at a rapid rate with most businesses and companies becoming more digitalized. Now is the time to ride the waves and get onboard with E-Commerce website design and make your business available in an online market place.
            </p>
            <a className="btn btn-orange default-padding mt-4 shadow text-capitalize" href="/get-started" role="button">Get started</a>
          </div>
        </div>
      </section>   

      <section className='w-100 e-commerce-design parallax text-dark'>
        <div className='container'>
          <div className='row gx-5 justify-content-center text-center' style={rowPadding}>
            <div className='col-12 col-md-10'>
              <p className='text-primary' style={{ fontFamily: "GraphikSuper", letterSpacing: "1px"}}>NEW ZEALAND</p>
              <h2 className='display-6 mb-4'>E-Commerce Web Design Specialist</h2>
              <div className='line-divider mx-auto my-3'></div>
              <p className='mt-4' style={pStyles}>
                Digital revolution has grown exponentially in the past years. There’s a large growth in eCommerce business and online stores that take advantage of the growing digital technology. Some small business finds it hard to compete and get noticed. Having a good quality website can give smaller to medium size business a competitive advantage.
              </p>
              <p style={pStyles}>
                Our Team of experienced Ecommerce Developers can give you options to select the best website that will work for your business and will help you get a step ahead of your competitors. 
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row flex-row-reverse gx-5' style={rowPadding}>
          <div className='col-md-6'>
            <div className='ms-md-5 position-relative'>
              <img src={eCommerceWebDesign} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h2 className='display-6 mb-4'>E-Commerce Website Design</h2>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              The way people sell their products and services has changed over the past years. Ecommerce web design plays a role in creating the baseline of any online store or business. Many online stores gain high profitability by taking advantage of all digital marketing tools available. However, to be really successful as an online ecommerce store, a business needs to be known to its target customers.
            </p>
            <p style={pStyles}>
              This is where Ecommerce design plays its important part- to expand website visitors by increasing the online ratings on popular search engines with the likes of Google. When a website is rated high on the popular search engine, high number of potential customers will have an easy access to the website which will save a business a lot of time, money, and resources in marketing.
            </p>
          </div>
        </div>
      </section>

      <section className='w-100 abstract text-dark'>
        <div className='container'>
          <div className='row gx-5' style={rowPadding}>
            <div className='col-md-6'>
              <div className='ms-md-5 position-relative'>
                  <img src={eCommerceWebDesignFunction} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
              </div>
            </div>
            <div className='col-md-6'>
              <h2 className='display-6 mb-4'>E-Commerce Website Design Functions</h2>
              <div className='line-divider'></div>
              <p className='mt-4' style={pStyles}>
                There’s a simple principle with E-commerce-the higher your website traffic (or people visiting your website or online store), the higher is your probability to get more sales and increase your revenue. 
              </p>
              <p style={pStyles}>
                An e-Ecommerce website can make this possible by promoting your products or services across different digital marketing channels. 
              </p>
              <p style={pStyles}>
                By focusing on brand retention approach to attain customer loyalty and further speeding up brand awareness can easily generate sales for an Ecommerce website.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row' style={rowPadding}>
          <h2 className='display-6 text-center mb-5'>GoogolWeb Advantage</h2>
          <div className="inline-scrollbox u-scroll-x home-integrations-scrollbox">

            <ul className='inline-scrollbox-wrapper'>
              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Competitive Pricing</h4>
                <p>Offering the most modern, innovative website at a very competitive rate- is the best offer there is!</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Get It Done By The Experts</h4>
                <p>Our experienced designers and developers guarantee you will be completely satisfied with the end results.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>You Are Always In The Know</h4>
                <p>Our Team will work closely with you every step of the way to ensure you are always informed of the progress of your website development.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Transparency of Operations</h4>
                <p>We value open communication and transparency in all our project for we believe a good alliance is founded on trust and openness.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>SEO Friendly Websites</h4>
                <p>We build websites with an SEO advantage to give you a headstart in SERPs.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Security</h4>
                <p>We build advanced security features into each one of the websites that we undertake to protect our client’s businesses from cyber attacks.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "248px" , padding: "35px 30px"}}>
                {/* <div className="feature-icon bg-gradient">
                  <FontAwesomeIcon icon={faDollarSign} style={iconStyles} />
                </div> */}
                <h4 className='h5 mb-3 text-uppercase'>Timely Delivery</h4>
                <p>Before we go ahead with your website development, we ensure that we give you a clear and realistic timeline to complete your website.</p>
              </li>
             
            </ul>
          </div>
        </div>
      </section>

      <ClientReviews></ClientReviews>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="E-commerce Design" />
)